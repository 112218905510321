@import 'assets/styles/_mixins.scss';
@import 'assets/styles/vars/_scss-variables.scss';

.card {
  position: relative;
  width: auto;
  // height: 497px;
  border-radius: 30px;
  overflow: hidden;
  aspect-ratio: 0.728 / 1;

  @include maxWidth($mobile-xl) {
    border-radius: 20.6px;
  }

  &__video {
    position: absolute;
    inset: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    // background-color: $blue-4;
    background: linear-gradient(
      180deg,
      #000,
      #04005f 16.15%,
      #350048 23.71%,
      rgba(78, 0, 125, 0.24) 36.46%,
      rgba(6, 1, 255, 0.53) 44.96%,
      rgba(0, 0, 0, 0.49) 71.57%,
      rgba(3, 1, 130, 0.18) 82.81%,
      transparent
    );
  }

  &__image_container {
    position: absolute;
    inset: 0;
    z-index: 10;
  }

  &_actions {
    @include mainPageAnimatedCardActions;
    // display: none;
    // width: fit-content;
    // box-shadow: 0px 20px 30px 0px #00000040;

    & button {
      box-shadow: 0px 4px 16px 0px #00000073;

      @include maxWidth($desktop-l) {
        font-size: 34px;
      }

      @include maxWidth($desktop-m) {
        font-size: 30px;
      }

      @include maxWidth($tablet-l) {
        font-size: 22px;
      }

      @include maxWidth($mobile-l) {
        font-size: 24px;
      }
    }
  }

  &:hover {
    & .card_actions {
      opacity: 1;
      transform: translateY(-20px);
    }
  }
}
