@import 'assets/styles/_mixins.scss';

.label {
  position: absolute;
  top: 25px;
  left: 23px;
  font-size: 22.41px;
  font-weight: 700;
  line-height: 22.41px;
  letter-spacing: -0.01em;
  background-color: $red-2;
  padding: 7.47px 7.47px 7.47px 7.47px;
  border-radius: 10px;
  z-index: 100000;
  text-transform: uppercase;

  @include maxWidth($tablet-s) {
    font-size: 18px;
  }
}
